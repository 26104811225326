import { useEffect, useState } from 'react';

const useScrollState = () => {
  const [isScroll, setIsScroll] = useState(false);
  const [prevScrollY, setPrevScrollY] = useState(0);

  const handleScroll = () => {
    // Fix bug with overflow:hidden
    if (prevScrollY && isScroll && !window.scrollY) {
      return;
    }

    setIsScroll(window.scrollY > 0);
    setPrevScrollY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return { isScroll, scrollY: prevScrollY };
};

export default useScrollState;
